
import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class'
import { QrcodeStream } from 'vue-qrcode-reader';
import qs from 'qs'
const isDevelopment = process.env.NODE_ENV !== 'production'

@Component({
    name: 'Home',
    data: () => ({}),
    computed: {
    },
    components: {
      QrcodeStream,
    },
})
export default class Home extends Vue {
    $refs!: {
        vue: Vue,
      element: HTMLInputElement,
      vues: Vue[],
      elements: HTMLInputElement[],
    }
    dialog: any = {
      title: '',
      text: '',
      subtitle: '',
      visible: false,
    }
    currentTitle: string = '用户信息'
    step: any = '1'
    isDev: boolean = false
    scanInfo: any = {
      cid: '',
      name: '',
      phone: '',
      other: '',
    }
    fbInfo: any = { // 采样舒适度 rateComfort；产品的易用性 rateUsability；提示信息直观性 rateIntuition
      rateComfort: null,
      rateUsability: null,
      rateIntuition: null,
      chooseType: undefined,
      type: undefined,
      remark: '',
    }
    durationTimer: any = null
    durationInterval: number = 0
    amenities: any = [1, 4]
    neighborhoods: any = [1]
    snackbar: any = {
        timeout: 5000,
        text: '',
        visible: false
    }
    get isPadHor () {
      return /^lg$|^md/i.test(this.$vuetify.breakpoint.name)
    }
    scanDone: boolean = false
    defaultCamera: string = 'rear'
    camera: string = 'off'
    result: any = null
    scanRequest: boolean = false
    showScanConfirmation: boolean = true
    async mounted () {
      const that: any = this
      that.isDev = isDevelopment;
      that.currentUrl = window.location.origin
      if (this.is_weixn) {
        const qrstr: any = this.$route.query.qrresult || ''
        if (qrstr) {
          let qrObj: any = {}
          try { qrObj = JSON.parse(qrstr) } catch (e) {}
          that.scanInfo = qrObj || { cid: '', name: '', phone: '' };
          that.scanDone = true
        }
      } else {
      }
    }
    currentUrl: string = ''
    // @Watch('$route')
    // onRouteChanged (val: any, oval: any) {
    //   const that: any = this
    //   console.log('kkk:', val)
    //   // if (val.name == 'cleanState') {
    //   //   that.loadCleanStateData()
    //   // }
    // }
    get is_weixn() {
      return false; // navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    }
    getScanInfo () {
      // 得到扫描 结果
      var qr = this.getQueryString("qrresult");
      if (qr) {
        alert(qr);
        var qrString = qr;
        try{
          //  根据 扫描结果qr 实现自定义逻辑
        }catch(exception){
          console.error('error of scan.:', exception)
        }
      }
    }
    // 解析扫描结果
    getQueryString (name: any) {
      var reg = new RegExp("\\b" + name + "=([^&]*)");
      var r = location.href.match(reg);
      if (r != null) return decodeURIComponent(r[1]);
    }
    get nextStepBtn () {
      if (this.step == 1) {
        return this.scanInfo.name && this.scanInfo.cid
      } else {
        return (this.fbInfo.rateComfort && this.fbInfo.rateUsability && this.fbInfo.rateIntuition)
      }
      return false
    }
    @Watch('dialog.visible')
    onDialogVisibleChg (visible: boolean) {
      const that: any = this
      if (!visible) {
        that.step = 1;
        that.scanDone = false;
        that.scanInfo.name = ''
        that.scanInfo.phone = ''
        that.scanInfo.cid = ''
        that.fbInfo.remark = ''
        // that.fbInfo.type = ''
        that.fbInfo.rateComfort = null
        that.fbInfo.rateUsability = null
        that.fbInfo.rateIntuition = null
        that.fbInfo.chooseType = undefined
        that.camera = 'rear'
      }
    }
    progressStepLinear (duration: number = 5000) {
      const that: any = this
      const step = duration / 100 // > 999 ? 10 : 5
      that.durationInterval = 0
      // alert(`pregres:${step}, duration:${duration}, durationInterval:${that.durationInterval}}`)
      this.durationTimer = setInterval(() => {
        if ((that.durationInterval >= 100) && that.durationTimer) {
          clearInterval(that.durationTimer)
          that.durationInterval = 0
          return
        }
        if (duration) {
          that.durationInterval += 1
        }
      }, step)
    }
    handleSubmit () {
      const that: any = this
      if (that.step == 1) {}
      else if (that.step == 2) {
        try {
          // alert(`input info:${that.fbInfo.chooseType}`)
          if (that.fbInfo.chooseType instanceof Array) that.fbInfo.chooseType = that.fbInfo.chooseType.join(',')
          that.axios.post(`/api/record`, qs.stringify({ ...that.scanInfo, ...that.fbInfo })).catch((e: any) => {
            that.snackbar.visible = true
            that.snackbar.text = '数据记录失败'
          })
        } catch (e) {
          console.log('api request error:', e)
          that.snackbar.visible = true
          that.snackbar.text = '数据记录失败'
        }
        that.dialog.visible = true
        that.dialog.title = '提交结果'
        that.dialog.text = '您的反馈已收到，感谢您的使用.'
        that.dialog.subtitle = '将在5秒后自动关闭'
        try {
          // that.progressStepLinear();
          setTimeout(() => {
            that.unpause();
            that.dialog.visible = false
          }, 5000)
        } catch (e) {
          alert(`自动关闭定时过程出错:${e}`)
        }
        return
      }
      that.step++
    }
    openCamera () {
      const that: any = this
      that.camera = 'rear'
      that.scanRequest = true;
      // camera = defaultCamera;
    }
    async onInit (promise) {
      try {
        await promise
        // this.camera = 'rear'
      } catch (e) {
        alert(`相机打开出错:${e}`)
        console.error(e)
      } finally {
        this.showScanConfirmation = this.camera === "off"
      }
    }
    async onDecode (content) {
      const that: any = this
      try {
        // console.log('扫描结果:', content)
        let qrObj: any = {}
        try {
          qrObj = JSON.parse(content)
        } catch (e) {
        }
        that.scanInfo = qrObj || {cid: '', name: '', phone: ''};
        // alert(`扫码结果:${that.scanInfo}`)
        that.scanDone = true
        this.result = content

        this.pause()
      } catch (e) {
        alert(`error decode:${e}`)
      }
      that.scanRequest = false;
      // await this.timeout(500)
      // this.unpause()
    }
    unpause () {
      this.camera = 'rear'
    }
    pause () {
      this.camera = 'off'
    }
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
}
