
import { Bar } from '@antv/g2plot';
import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, Mutation } from 'vuex-class'
import qs from 'qs'
const isDevelopment = process.env.NODE_ENV !== 'production'

@Component({
    name: 'Report',
    data: () => ({}),
    computed: {
    },
    components: {
    },
})
export default class Report extends Vue {
    $refs!: {
        vue: Vue,
      element: HTMLInputElement,
      vues: Vue[],
      elements: HTMLInputElement[],
    }
    dialog: any = {
      title: '',
      text: '',
      subtitle: '',
      visible: false,
    }
    currentTitle: string = '用户信息'
    isDev: boolean = false
    snackbar: any = {
        timeout: 5000,
        text: '',
        visible: false
    }
    get isPadHor () {
      return /^lg$|^md/i.test(this.$vuetify.breakpoint.name)
    }
    data: any = [
      // {
      //   rate: '1991',
      //   value: 3,
      //   type: 'Lon',
      // },
      // {
      //   rate: '1992',
      //   value: 4,
      //   type: 'Lon',
      // },
    ]
    stackedBarPlot: any = null
    stackedBarPlotConfig: any = {}
    fetchingData: boolean = true
    statisResult: any = {
      numsAll: null,
      numsCur: null,
      numsMale: null,
      numsFemale: null,
      rateComfort: {
        sum: null,
        avg: null,
        max: null,
        min: null
      },
      rateUsability: {
        sum: null,
        avg: null,
        max: null,
        min: null
      },
      rateIntuition: {
        sum: null,
        avg: null,
        max: null,
        min: null
      }
    }
    async mounted () {
      const that: any = this
      that.isDev = isDevelopment;
      that.currentUrl = window.location.origin
      that.stackedBarPlotConfig = {
        container: 'container',
        autoFit: true,
        height: 500,
        data: that.data, //.reverse(),
        isStack: true,
        xField: 'value',
        yField: 'rate',
        seriesField: 'type',
        label: {
          // 可手动配置 label 数据标签位置
          position: 'middle', // 'left', 'middle', 'right'
          // 可配置附加的布局方法
          layout: [
            // 柱形图数据标签位置自动调整
            { type: 'interval-adjust-position' },
            // 数据标签防遮挡
            { type: 'interval-hide-overlap' },
            // 数据标签文颜色自动调整
            { type: 'adjust-color' },
          ],
        },
      }
      if (!that.stackedBarPlot) that.stackedBarPlot = new Bar('container', that.stackedBarPlotConfig);
      that.fetchStatist()
      that.fetchRecord()
    }
    currentUrl: string = ''
    pageResult: any = {
      loading: false,
      limit: 10,
      page: 1,
      pageSum: 0,
      result: []
    }
    headers: any = [
      {
        text: '采样人',
        align: 'start',
        value: 'name',
      },
      { text: '舒适度', value: 'rateComfort' },
      { text: '易用性', value: 'rateUsability' },
      { text: '直观性', value: 'rateIntuition' },
      { text: '采样日期', value: 'date' },
    ]
    async fetchRecord () {
      const that: any = this
      that.pageResult.loading = true;
      try {
        const res = ((await that.axios.get(`/api/record`, { params: { page: that.pageResult.page, limit: that.pageResult.limit } })) || { data: { result: null } }).data;
        that.pageResult.result = res.result;
        that.pageResult.pageSum = res.pageSum;
      } catch (e) {
        console.log('error to load record:', e)
      }
      that.pageResult.loading = false;
    }
    async fetchStatist () {
      const that: any = this
      that.fetchingData = true
      try {
          that.statisResult = ((await that.axios.get(`/api/record/report`, { params: { data: ''} })) || { data: { result: null } }).data.result;
          that.data = [];
          that.data.push({
            rate: '舒适度',
            value: that.statisResult.rateComfort.sum || 0,
            type: '总数',
          })
          that.data.push({
            rate: '舒适度',
            value: that.statisResult.rateComfort.avg || 0,
            type: '平均值',
          })
          that.data.push({
            rate: '舒适度',
            value: that.statisResult.rateComfort.max || 0,
            type: '最大值',
          })
          that.data.push({
            rate: '舒适度',
            value: that.statisResult.rateComfort.min || 0,
            type: '最小值',
          })
          that.data.push({
            rate: '易用性',
            value: that.statisResult.rateUsability.avg || 0,
            type: '平均值',
          })
          that.data.push({
            rate: '易用性',
            value: that.statisResult.rateUsability.sum || 0,
            type: '总数',
          })
          that.data.push({
            rate: '易用性',
            value: that.statisResult.rateUsability.max || 0,
            type: '最大值',
          })
          that.data.push({
            rate: '易用性',
            value: that.statisResult.rateUsability.min || 0,
            type: '最小值',
          })
          that.data.push({
            rate: '直观性',
            value: that.statisResult.rateIntuition.avg || 0,
            type: '平均值',
          })
          that.data.push({
            rate: '直观性',
            value: that.statisResult.rateIntuition.sum || 0,
            type: '总数',
          })
          that.data.push({
            rate: '直观性',
            value: that.statisResult.rateIntuition.max || 0,
            type: '最大值',
          })
          that.data.push({
            rate: '直观性',
            value: that.statisResult.rateIntuition.min || 0,
            type: '最小值',
          })
          try {
            const that: any = this
            that.stackedBarPlot.update({ ...that.stackedBarPlotConfig, data: that.data })
            that.stackedBarPlot.render();
          } catch (e) {
            console.log('error of create chart.:', e)
          }
        } catch (e) {
          console.log('error of load data:', e)
        }
      that.fetchingData = false
      that.loadCharts()
    }
    loadCharts () {
      const that: any = this
      console.log('stackedBarPlot:....:', that.stackedBarPlot)
      // that.stackedBarPlot.update();
    }
    // @Watch('$route')
    // onRouteChanged (val: any, oval: any) {
    //   const that: any = this
    //   console.log('kkk:', val)
    //   // if (val.name == 'cleanState') {
    //   //   that.loadCleanStateData()
    //   // }
    // }
    get is_weixn() {
      return false; // navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
    }
    get nextStepBtn () {
      if (this.step == 1) {
        return this.scanInfo.name && this.scanInfo.cid
      } else {
        return (this.fbInfo.rateComfort && this.fbInfo.rateUsability && this.fbInfo.rateIntuition)
      }
      return false
    }
    @Watch('pageResult.limit')
    @Watch('pageResult.page')
    paginationHandle () {
      const that: any = this
      const ps: number = Number.isNaN(parseInt(that.pageResult.limit, 10)) ? that.pageResult.pageSum : that.pageResult.limit
      if (that.pageResult.page > Math.ceil(that.pageResult.pageSum / ps)) that.pageResult.page = 1
      that.fetchRecord()
    }
    handleSubmit () {
      const that: any = this
      if (that.step == 1) {}
      else if (that.step == 2) {
        try {
          // alert(`input info:${that.fbInfo.chooseType}`)
          if (that.fbInfo.chooseType instanceof Array) that.fbInfo.chooseType = that.fbInfo.chooseType.join(',')
          that.axios.post(`/api/record`, qs.stringify({ ...that.scanInfo, ...that.fbInfo })).catch((e: any) => {
            that.snackbar.visible = true
            that.snackbar.text = '数据记录失败'
          })
        } catch (e) {
          console.log('api request error:', e)
          that.snackbar.visible = true
          that.snackbar.text = '数据记录失败'
        }
        that.dialog.visible = true
        that.dialog.title = '提交结果'
        that.dialog.text = '您的反馈已收到，感谢您的使用.'
        that.dialog.subtitle = '将在5秒后自动关闭'
        try {
          // that.progressStepLinear();
          setTimeout(() => {
            that.unpause();
            that.dialog.visible = false
          }, 5000)
        } catch (e) {
          alert(`自动关闭定时过程出错:${e}`)
        }
        return
      }
      that.step++
    }
}
