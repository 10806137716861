/**
 * created by vacant
 * email: wq.chen@broadcare.com.cn
 * date: 2022-07-10
 */
import axios from 'axios';
import store from '@/store';
import router from '@/router';
// import utils from './BcAxiosUtils.js'

axios.defaults.timeout = 60000; // 响应时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; // 配置请求头

axios.defaults.headers = {
  // common: { ...(axios.defaults.headers['common'] || {}), Accept: 'application/x-www-form-urlencoded;charset=UTF-8' },
  ...axios.defaults.headers,
  // 解决IE下axios请求带缓存的问题 create by vacant at 2018-1-19
  // @ts-ignore
  Pragma: (!!window.ActiveXObject || 'ActiveXObject' in window) ? 'no-cache' : '',
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
};

axios.interceptors.request.use(
  // @ts-ignore
  (config: any) => {
    try {
      let token = store.getters.token
      // console.log('token req:', String(token).substr(-10, 10))
      // if (!token) store.commit('checkLogin') // 刷新token（防止页面刷新）
      // console.log('token req 2:', String(token).substr(-10, 10))
      token = store.getters.token
      if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers['Authorization'] = `Bearer ${store.getters.token}`
      }
    } catch (e) {
      console.log('error of handle request.:', e)
    }
    return config
  },
  err => Promise.reject(err),
);
// http response 拦截器
axios.interceptors.response.use((response: any) => { // 有新token时，更新令牌
  try {
    const refreshToken = response.headers && response.headers.refresh_token
    // console.log('token res:', String(refreshToken).substr(-10, 10))
    // 更新token
    if (refreshToken) {
      store.commit('setToken', refreshToken)
    }
    return response
  } catch (e) {
    console.log('error of interceptors response:', e)
  }
},
(error: any) => {
  if (error.response) {
    switch (error.response.status) {
        case 401:
          // 返回 401 清除token信息并跳转到登录页面
          // store.commit('setSessionTimeout', true);
          store.commit('logoutHandle');
          if (router.currentRoute.name !== 'login') {
            router.replace({
              path: '/login',
              query: { redirect: router.currentRoute.fullPath, loginOver: '登录过期，请重新登录' },
            });
          }
          break;
        default:
          break;
    }
  }
  console.log('error on response:', error)
  return Promise.reject(error.response && error.response.data || error || '未知错误'); // 返回接口返回的错误信息
});

export default axios;
